// extracted by mini-css-extract-plugin
export var agenderGreen = "ProjectCard-module--agender-green--5CqDA";
export var description = "ProjectCard-module--description--YJ9RL";
export var even = "ProjectCard-module--even--HdNLW";
export var fadingIn = "ProjectCard-module--fading-in--mfKOi";
export var fadingOut = "ProjectCard-module--fading-out--KopjC";
export var genderQueerPurple = "ProjectCard-module--gender-queer-purple--roOJX";
export var imageContainer = "ProjectCard-module--image-container--5bhej";
export var links = "ProjectCard-module--links--UUBYt";
export var odd = "ProjectCard-module--odd--2IKeE";
export var skills = "ProjectCard-module--skills--bAzjk";
export var textContainer = "ProjectCard-module--text-container--IV6lc";
export var transBlue = "ProjectCard-module--trans-blue--r5TFR";
export var transPink = "ProjectCard-module--trans-pink--bCrTE";