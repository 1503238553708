// extracted by mini-css-extract-plugin
export var about = "About-module--about--eTjoG";
export var aboutInfo = "About-module--about-info--qulVy";
export var button = "About-module--button--rlgZE";
export var css = "About-module--css--owq9+";
export var fadingIn = "About-module--fading-in--0FRDE";
export var fadingOut = "About-module--fading-out--Y8jwC";
export var firebase = "About-module--firebase--8E5H2";
export var headshotContainer = "About-module--headshot-container--OKTqg";
export var html = "About-module--html--PIR0n";
export var javascript = "About-module--javascript--EBe6W";
export var jquery = "About-module--jquery--h5bej";
export var react = "About-module--react--ZjLdK";
export var skill = "About-module--skill--063bJ";
export var skills = "About-module--skills--NrZbp";
export var skillsBox = "About-module--skills-box--wqw31";
export var visualHeading = "About-module--visual-heading--Unv+l";