import React from 'react';

const ScrollIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 48 72"
    >
      <g>
        <g id="Scroll_Icon">
          <g id="Group_20">
            <path
              id="Path_63"
              className="st0"
              fill="#b57edc"
              d="M13.7,2c1.1-0.7,2.5-1.1,3.9-1c6.5,0,13,0,19.6,0c1,0,1.9,0,2.9,0c0.5,0,1,0.1,1.6,0.3
            c3.2,1,4.9,4.4,3.9,7.6c-0.8,2.4-3,4.1-5.6,4.2l0.2,0c-0.5,0-1-0.1-1.6,0c0,6.6,0,13.2,0,19.8c0,0.3,0,0.5,0,0.8
            c0.1,1.2-0.1,2.4-0.8,3.5c-1,1.7-2.8,2.7-4.8,2.7c-0.4,0-0.8-0.1-1.2,0c-7.3,0-14.6,0-21.9,0c-0.7,0-1.3,0-1.9-0.2
            c-1.1-0.3-2-1-2.6-2c-0.7-1-0.9-2.3-0.7-3.5c0.1-0.4,0.4-0.7,0.8-0.7c0.2,0,0.4,0,0.6,0c1.7,0,3.3,0,5,0c0-8.8,0-17.6,0-26.4
            c0-0.8,0.2-1.6,0.5-2.3C11.9,3.6,12.6,2.6,13.7,2 M13.3,5.5C13.1,6.1,13,6.7,13,7.3c0,8.7,0,17.4,0,26.1c5.1,0,10.2,0,15.3,0
            c0.4,0,0.8,0.3,1,0.7c0.1,0.3,0,0.6,0.1,0.9c0.2,1.1,0.9,2,1.9,2.5c0.3,0.2,0.6,0.3,0.9,0.3c0.2,0,0.5,0,0.7,0.1
            c1.1,0,2.2-0.5,2.9-1.4c0.5-0.8,0.8-1.7,0.7-2.6c0-8.9,0-17.7,0-26.6c0-1.5,0.5-3,1.5-4.1c-6.9,0-13.8,0-20.7,0
            C15.5,3,14,3.9,13.3,5.5 M39.5,4.4c-0.3,0.4-0.6,0.8-0.8,1.2c0.6,0,1.1,0,1.7,0c0.3,0,0.5,0.1,0.8,0.2c0.4,0.2,0.6,0.6,0.5,1
            c0,0.2-0.1,0.4-0.3,0.6c-0.3,0.5-0.3,1.2-0.1,1.7c0.2,0.4,0.5,0.8,0.8,1c0.5-0.4,0.8-0.9,1.1-1.4c0.4-1,0.5-2.1,0.1-3
            c-0.4-1-1.1-1.8-2.1-2.2c-0.1-0.1-0.2-0.1-0.3,0C40.4,3.6,39.9,4,39.5,4.4 M38.4,11c0.6,0,1.1,0,1.7,0c-0.5-0.7-0.9-1.5-0.9-2.3
            c0-0.4,0-0.7,0-1.1c-0.2,0-0.5,0-0.7,0C38.4,8.7,38.4,9.9,38.4,11 M6.5,35.4c0.1,0.5,0.3,0.9,0.6,1.3c0.5,0.7,1.3,1,2.2,1
            c6.4,0,12.8,0,19.2,0c-0.5-0.7-0.9-1.5-1.1-2.3C20.4,35.4,13.4,35.4,6.5,35.4L6.5,35.4z"
            />
            <path
              id="Path_64"
              className="st0"
              fill="#b57edc"
              d="M3.7,3.9c0.4-0.3,0.9-0.2,1.2,0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.5,0.1,0.7
            c0.3-0.1,0.6,0,0.8,0.2C6.3,5.4,6.3,5.9,6,6.2c0,0,0,0,0,0.1C5.8,6.5,5.4,6.6,5.1,6.5c0,0.3,0,0.6-0.2,0.9
            C4.6,7.7,4.1,7.8,3.7,7.5c0,0-0.1-0.1-0.1-0.1C3.5,7.1,3.4,6.8,3.4,6.5c-0.3,0-0.6,0-0.9-0.3c-0.3-0.3-0.3-0.9,0-1.2
            C2.6,5,2.7,5,2.7,5c0.2-0.1,0.5-0.2,0.7-0.1C3.4,4.5,3.5,4.2,3.7,3.9z"
            />
            <path
              id="Path_65"
              className="st0"
              fill="#b57edc"
              d="M8.2,9.2c0.5-0.2,1.1,0,1.3,0.6c0.2,0.5,0,1.1-0.6,1.3c-0.5,0.2-1.1,0-1.3-0.6c0,0,0,0,0-0.1
            C7.4,10,7.7,9.4,8.2,9.2z"
            />
            <path
              id="Path_66"
              className="st0"
              fill="#b57edc"
              d="M18.5,12c0.8,0,1.6,0,2.5,0c2.8,0,5.7,0,8.5,0c0.2,0,0.4,0,0.7,0.1c0.5,0.2,0.8,0.8,0.6,1.3
            c-0.1,0.4-0.5,0.7-1,0.7H18.7c-0.6,0-1-0.5-1-1C17.7,12.5,18,12.1,18.5,12z"
            />
            <path
              id="Path_67"
              className="st0"
              fill="#b57edc"
              d="M3.3,15.3c0.6-0.1,1.1,0.3,1.2,0.8c0.1,0.6-0.3,1.1-0.8,1.2c-0.6,0.1-1.1-0.3-1.2-0.8
            c0-0.1,0-0.2,0-0.3C2.6,15.7,2.9,15.4,3.3,15.3z"
            />
            <path
              id="Path_68"
              className="st0"
              fill="#b57edc"
              d="M24.9,15.4c0.2,0,0.4,0,0.5,0c2.3,0,4.6,0,7,0c0.6,0,1,0.5,1,1.1c0,0,0,0,0,0
            c-0.1,0.5-0.5,0.9-1,0.9c-2.4,0-4.9,0-7.3,0c-0.5,0-1-0.4-1-1C24.1,16,24.4,15.5,24.9,15.4z"
            />
            <path
              id="Path_69"
              className="st0"
              fill="#b57edc"
              d="M17,15.9c0.2,0,0.3,0,0.5,0c1.5,0,3,0,4.5,0c0.5,0,1,0.5,1,1c0,0.6-0.5,1-1,1
            c-1.5,0-3.1,0-4.6,0c-0.6,0-1-0.5-1-1C16.3,16.4,16.6,16,17,15.9L17,15.9z"
            />
            <path
              id="Path_70"
              className="st0"
              fill="#b57edc"
              d="M6.1,19.8c0.3-0.3,0.9-0.3,1.2,0c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.2,0.5,0.2,0.8
            c0.3,0,0.6,0,0.8,0.2c0.4,0.3,0.4,0.8,0.2,1.2c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.6,0.3-0.9,0.2c0.1,0.3,0,0.6-0.2,0.9
            c-0.3,0.3-0.8,0.4-1.2,0.1c0,0,0,0-0.1-0.1c-0.2-0.2-0.3-0.6-0.2-0.9c-0.3,0-0.6,0-0.9-0.2c-0.3-0.3-0.3-0.8,0-1.2
            c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.2,0.8-0.2C5.8,20.4,5.9,20,6.1,19.8z"
            />
            <path
              id="Path_71"
              className="st0"
              fill="#b57edc"
              d="M18,20.2c3.3,0,6.7,0,10,0c0.4,0,0.7,0.2,0.9,0.5c0.3,0.5,0.2,1.1-0.3,1.4
            c-0.2,0.1-0.4,0.2-0.6,0.2c-3.3,0-6.6,0-9.9,0c-0.6,0-1-0.5-1-1C17.1,20.7,17.5,20.3,18,20.2L18,20.2z"
            />
            <path
              id="Path_72"
              className="st0"
              fill="#b57edc"
              d="M26.2,28c1.7,0,3.3,0,5,0c0.5,0,1,0.4,1,1c0,0.6-0.4,1-0.9,1.1c0,0,0,0,0,0c-1.7,0-3.3,0-5,0
            c-0.4,0-0.7-0.2-0.9-0.5c-0.3-0.5-0.1-1.1,0.4-1.4C25.9,28.1,26,28,26.2,28L26.2,28z"
            />
            <path
              id="Path_73"
              className="st0"
              fill="#b57edc"
              d="M40.5,30.4c0.5-0.2,1.1,0.1,1.3,0.7c0.2,0.5-0.1,1.1-0.7,1.3c-0.5,0.2-1.1-0.1-1.3-0.7
            c0-0.1-0.1-0.3,0-0.4C39.8,30.9,40.1,30.5,40.5,30.4z"
            />
            <path
              id="Path_74"
              className="st0"
              fill="#b57edc"
              d="M42.3,35.1c0.3-0.3,0.9-0.3,1.2,0c0,0,0.1,0.1,0.1,0.1c-0.1,0.4,0.1,0.9,0.5,1
            c0.2,0.1,0.3,0,0.5,0c0.3,0.3,0.4,0.9,0,1.2c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c0.1,0.3,0,0.7-0.2,0.9
            c-0.3,0.3-0.9,0.3-1.2,0c0,0,0,0,0-0.1c-0.2-0.2-0.2-0.6-0.2-0.8c-0.3,0-0.6,0-0.8-0.2c-0.3-0.3-0.4-0.8,0-1.2c0,0,0,0,0.1-0.1
            c0.2-0.2,0.5-0.2,0.8-0.2C42,35.7,42.1,35.4,42.3,35.1z"
            />
            <path
              id="Path_75"
              className="st0"
              fill="#b57edc"
              d="M38.6,39.4c0.5-0.1,1.1,0.2,1.3,0.7c0.1,0.5-0.2,1.1-0.7,1.3c-0.5,0.1-1.1-0.2-1.3-0.7
            c0-0.1,0-0.2,0-0.3C37.9,39.9,38.2,39.6,38.6,39.4z"
            />
          </g>
        </g>
        <path
          id="Path_76"
          className="st1"
          fill="none"
					stroke="#b57edc"
					strokeWidth={3}
					strokeLineCap="round"
					strokeLineJoin="round"
          d="M31.1,63L24,70.1L16.9,63"
        />
      </g>
    </svg>
  );
};

export default ScrollIcon;
