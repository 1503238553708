import React from 'react';

const JqueryLogo = () => {
  return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 72 72"
		>
			<circle id="Ellipse_4" className="st0" fill="#0868ac" cx="36" cy="36" r="32.5"/>
      <g id="Group_33" transform="translate(851.995 1397)">
        <path id="Path_88" className="st1" fill="#fff" d="M-814-1339L-814-1339c-1.8,0-3.6-0.2-5.4-0.5c-0.3-0.1-0.5-0.1-0.7-0.2l-0.2-0.1c0,0-0.1,0-0.1,0
          c-0.4-0.1-0.6-0.2-0.9-0.3l-0.1-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c0,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.2-0.9-0.4l-0.1,0
          c-0.3-0.1-0.5-0.2-0.7-0.3c0,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.3
          l-0.2-0.1c-0.3-0.2-0.6-0.4-0.8-0.5l-0.7-0.5c0,0,0,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.3-0.5-0.4l-1-0.8
          c-0.2-0.2-0.4-0.3-0.6-0.5c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.3-0.5-0.5l-0.2-0.1l-0.1-0.1c-0.2-0.1-0.3-0.3-0.5-0.5l-0.2-0.2
          c-0.2-0.2-0.4-0.4-0.5-0.6c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.5-0.6l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-0.6-0.7
          c-0.1-0.2-0.3-0.4-0.4-0.6l-0.1-0.1c0,0,0,0,0,0l0,0c-0.2-0.2-0.3-0.5-0.5-0.7c0,0-0.1-0.1-0.1-0.2l-0.2-0.3
          c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.2l-0.3-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3
          l0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-2.8-5.7-3.6-14.9,0.5-20.8c1-1.5,2.2-2.9,3.5-4.2c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0.1,1.4
          c-3,4.1-2,15.3,1.9,20.6c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.1,0.2,0.2,0.2l0.4,0.5c0.2,0.3,0.4,0.5,0.6,0.7
          c0.2,0.2,0.4,0.4,0.5,0.6l0.7,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.3,0.5,0.5l0.2,0.2
          c0.1,0.1,0.2,0.2,0.4,0.3s0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.2,0.5,0.3l0.1,0.1c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0.1,0.1,0.1,0.2,0.1
          c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0,0.2,0.1,0.3,0.2l0.8,0.4c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.2,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3
          l0.1,0.1c0.2,0.1,0.5,0.2,0.7,0.3l0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.2l0.1,0c0.2,0.1,0.5,0.2,0.8,0.2l0.4,0.1c0.2,0.1,0.4,0.1,0.6,0.2
          c7.9,1.8,14.9,0,18.5-4.8c0.3-0.4,1-0.5,1.4-0.2c0.4,0.3,0.5,0.7,0.3,1.2C-795.1-1350.5-799.7-1339-814-1339z"/>
        <path id="Path_89" className="st1" fill="#fff" d="M-808.4-1353c-1.3,0-2.6-0.1-3.9-0.3c-0.3,0-0.5-0.1-0.8-0.2l-0.4-0.1c-0.2-0.1-0.4-0.1-0.6-0.2
          l-0.1,0l-0.1,0c-0.3-0.1-0.5-0.2-0.7-0.3l-0.3-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1,0-0.1,0l-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
          c-0.1-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.5-0.3-0.7-0.4l-0.1-0.1l-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1-0.1
          c-0.3-0.2-0.5-0.4-0.8-0.6l-0.1-0.1c-0.3-0.3-0.6-0.5-0.8-0.7c0,0,0,0-0.1-0.1l0,0c-0.2-0.2-0.4-0.4-0.6-0.6l-0.1-0.1l-0.1-0.1
          c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.5-1-1.1-1.4-1.7l-0.1-0.2c-0.5-0.7-0.9-1.4-1.3-2.1c-2.4-4.8-2.2-10.4,0.5-14.4l0,0
          c0.9-1.2,2-2.3,3.1-3.3c0.4-0.4,1.1-0.3,1.4,0.1c0.3,0.3,0.3,0.8,0.1,1.1c-1.9,3.2-2.5,6.8-1.7,9.5c1,3.1,2.8,5.8,5.4,7.8l0.1,0.1
          c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0.1l0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3l0.1,0.1l0.1,0.1
          c0.2,0.1,0.3,0.2,0.4,0.2l0.6,0.3c0.2,0.1,0.4,0.2,0.7,0.3l0.1,0.1c0.2,0.1,0.4,0.2,0.5,0.2l0.3,0.1c0.2,0.1,0.4,0.1,0.5,0.2
          c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.5,0.2,0.8,0.2c5.4,1.4,10.7-0.3,13.5-4.4c0.3-0.5,0.9-0.6,1.4-0.3c0.4,0.2,0.5,0.7,0.4,1.1
          c-2.1,5.9-7.7,9.8-13.9,9.7C-808.1-1353-808.3-1353-808.4-1353z"/>
        <path id="Path_90" className="st1" fill="#fff" d="M-803.7-1365c-0.1,0-0.2,0-0.4,0c-0.8,0-1.5-0.1-2.3-0.3c-0.2,0-0.3-0.1-0.5-0.1l-0.1,0l-0.2-0.1
          c-0.2-0.1-0.3-0.1-0.4-0.1c-3-1.2-5.5-3.5-6.8-6.5c-0.5-1.2-0.8-2.6-0.8-3.9c0-1.7,0.5-3.4,1.5-4.9c0,0,0,0,0,0
          c0.5-0.8,1.2-1.4,2-1.9c0.5-0.2,1.1,0,1.3,0.5c0.2,0.3,0.1,0.7,0,1c-1.8,2.8-0.6,7.8,2.5,10.1c0.5,0.4,1.1,0.8,1.7,1.1
          c0.3,0.1,0.6,0.3,1,0.4c0.3,0.1,0.5,0.2,0.8,0.3c3.5,0.9,6,0.2,7.4-1.9l0,0c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4l0,0v0
          c0,0.1-0.1,0.2-0.2,0.3C-796.9-1366.9-800.1-1364.9-803.7-1365z"/>
      </g>
		</svg>
	);
};

export default JqueryLogo;
